import { Component, OnInit, Input } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { Location } from '@angular/common';

@Component({
  selector: 'barra-navegacion',
  templateUrl: './barra.component.html',
  styleUrls: ['./barra.component.scss'],
})
export class BarraComponent implements OnInit {

  @Input() tituloBarra: String;
  @Input() backVisible: boolean;
  
  constructor(
    private menuCtrl: MenuController,
    private location: Location
  ) { }

  ngOnInit() {}

  toggleMenu(){
    this.menuCtrl.toggle();
  }
  regresar(){ 
    this.location.back();
  }
}
