import { Injectable } from '@angular/core';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { DEFAULTS } from '../app.conf';

@Injectable({
  providedIn: 'root'
})
export class PushService {

  constructor(
    private oneSignal: OneSignal
  ) { }

  configuracionInicial(){
    this.oneSignal.startInit(DEFAULTS.ONESIGNAL_APP_ID, DEFAULTS.FIREBASE_ID_REMITENTE);
    this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.InAppAlert);
    this.oneSignal.handleNotificationReceived().subscribe(( noti ) => {
    // do something when notification is received
      console.log("Notificacion Recibida", noti );
    });
    this.oneSignal.handleNotificationOpened().subscribe((noti) => {
      // do something when a notification is opened
      console.log("Notificacion abierta", noti );
    });
    this.oneSignal.endInit();
  }
}
