import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuComponent } from './menu/menu.component';
import { IonicModule } from '@ionic/angular';
import { BarraComponent } from './barra/barra.component';
import { MatDatepickerModule } from '@angular/material';



@NgModule({
  declarations: [
    MenuComponent,
    BarraComponent,
  ],
  exports: [
    MenuComponent,
    BarraComponent,
    MatDatepickerModule
  ],
  imports: [
    CommonModule,
    IonicModule,
    MatDatepickerModule
  ]
})
export class ComponentsModule { }
