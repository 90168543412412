export  const DEFAULTS = {
   URL_BASE: "https://localhost/Anahuac_Server/",
   ONESIGNAL_APP_ID: "7ffb6e31-bd58-43cf-a9b8-0a2a9b19e047",
   ONESIGNAL_REST_API_KEY: "YTA0ODBkMDUtNTIwNC00YTlmLTkzN2UtOGVlYjNjZjJjMWJk",
   FIREBASE_ID_REMITENTE: "636751196662"
}
export const DATE_FORMAT = {
   parse: {
     dateInput: 'LL',
   },
   display: {
     dateInput: 'YYYY-MM-DD',
     monthYearLabel: 'YYYY',
     dateA11yLabel: 'LL',
     monthYearA11yLabel: 'YYYY',
   },
 };