import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuController } from '@ionic/angular'; //import MenuController to access toggle() method.


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public menuCtrl: MenuController
  ) { }

  ngOnInit() {

  }

  cerrarMenu(){
    this.menuCtrl.toggle();
  }

  irInicio(){
    this.router.navigate(['/inicio']);
    this.cerrarMenu();
  }
  irActivos(){
    this.router.navigate(['/lista-de-activos']);
    this.cerrarMenu();
  }
  irDepartamentos(){
    this.router.navigate(['/lista-de-departamentos']);
    this.cerrarMenu();
  }
  irPersonal(){
    this.router.navigate(['/listar-personal']);
    this.cerrarMenu();
  }
  irFolios(){
    this.router.navigate(['/listar-folios']);
    this.cerrarMenu();
  }
  irReportes(){
    this.router.navigate(['/listar-reportes']);
    this.cerrarMenu();
  }
  irNotificaciones(){
    this.router.navigate(['/listar-notificaciones']);
    this.cerrarMenu();
  }
  irMantenimiento(){
    this.router.navigate(['/listar-mantenimiento']);
    this.cerrarMenu();
  }
  irUbicaciones(){
    this.router.navigate(['/listar-ubicaciones']);
    this.cerrarMenu();
  }
  //
}
