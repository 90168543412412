import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';


const routes: Routes = [
  { path: '', redirectTo: 'lista-de-activos', pathMatch: 'full' }, 
  {
    path: 'inicio',
    loadChildren: () => import('./pages/inicio/inicio.module').then( m => m.InicioPageModule)
  },
  {
    path: 'lista-de-activos',
    loadChildren: () => import('./pages/activos/botones/botones.module').then( m => m.BotonesPageModule)
  },
  {
    path: 'confirmar-eliminar/:idActivo',
    loadChildren: () => import('./pages/activos/confirmar-eliminar/confirmar-eliminar.module').then( m => m.ConfirmarEliminarPageModule)
  },
  {
    path: 'editar-activo/:idActivo',
    loadChildren: () => import('./pages/activos/editar-activo/editar-activo.module').then( m => m.EditarActivoPageModule)
  },
  {
    path: 'nuevo-activo',
    loadChildren: () => import('./pages/activos/nuevo-activo/nuevo-activo.module').then( m => m.NuevoActivoPageModule)
  },
  {
    path: 'escaner-nuevo-activo',
    loadChildren: () => import('./pages/activos/escaner/escaner.module').then( m => m.EscanerPageModule)
  },
  {
    path: 'activos-historial-asignaciones',
    loadChildren: () => import('./pages/activos/historial-asignaciones/historial-asignaciones.module').then( m => m.HistorialAsignacionesPageModule)
  },
  {
    path: 'activos-historial-ubicaciones',
    loadChildren: () => import('./pages/activos/historial-ubicaciones/historial-ubicaciones.module').then( m => m.HistorialUbicacionesPageModule)
  },
  {
    path: 'lista-de-departamentos',
    loadChildren: () => import('./pages/departamentos/inicio/inicio.module').then( m => m.InicioPageModule)
  },
  {
    path: 'nuevo-departamento',
    loadChildren: () => import('./pages/departamentos/nuevo-departamento/nuevo-departamento.module').then( m => m.NuevoDepartamentoPageModule)
  },
  {
    path: 'detalles-departamento/:idDepartamento',
    loadChildren: () => import('./pages/departamentos/detalles-departamento/detalles-departamento.module').then( m => m.DetallesDepartamentoPageModule)
  },
  {
    path: 'eliminar-departamento/:idDepartamento',
    loadChildren: () => import('./pages/departamentos/eliminar-departamento/eliminar-departamento.module').then( m => m.EliminarDepartamentoPageModule)
  },
  {
    path: 'departamenos-listar-personal-asignado',
    loadChildren: () => import('./pages/departamentos/listar-personal-asignado/listar-personal-asignado.module').then( m => m.ListarPersonalAsignadoPageModule)
  },
  {
    path: 'departamentos-listar-equipo-asignado',
    loadChildren: () => import('./pages/departamentos/listar-equipo-asignado/listar-equipo-asignado.module').then( m => m.ListarEquipoAsignadoPageModule)
  },
  {
    path: 'listar-personal',
    loadChildren: () => import('./pages/personal/listar/listar.module').then( m => m.ListarPageModule)
  },
  {
    path: 'nuevo-personal',
    loadChildren: () => import('./pages/personal/nuevo/nuevo.module').then( m => m.NuevoPageModule)
  },
  {
    path: 'editar-personal/:idPersonal',
    loadChildren: () => import('./pages/personal/editar/editar.module').then( m => m.EditarPageModule)
  },
  {
    path: 'eliminar-personal/::idPersonal',
    loadChildren: () => import('./pages/personal/eliminar/eliminar.module').then( m => m.EliminarPageModule)
  },
  {
    path: 'listar-folios',
    loadChildren: () => import('./pages/foliosSap/listar/listar.module').then( m => m.ListarPageModule)
  },
  {
    path: 'editar-folios/:idFolio',
    loadChildren: () => import('./pages/foliosSap/editar/editar.module').then( m => m.EditarPageModule)
  },
  {
    path: 'eliminar-folios/:idFolio',
    loadChildren: () => import('./pages/foliosSap/eliminar/eliminar.module').then( m => m.EliminarPageModule)
  },
  {
    path: 'nuevo-folios',
    loadChildren: () => import('./pages/foliosSap/nuevo/nuevo.module').then( m => m.NuevoPageModule)
  },
  {
    path: 'listar-reportes',
    loadChildren: () => import('./pages/reportes/listar/listar.module').then( m => m.ListarPageModule)
  },
  {
    path: 'listar-notificaciones',
    loadChildren: () => import('./pages/notificaciones/listar/listar.module').then( m => m.ListarPageModule)
  },
  {
    path: 'eliminar-notificacion/:idNotificacion',
    loadChildren: () => import('./pages/notificaciones/eliminar/eliminar.module').then( m => m.EliminarPageModule)
  },
  {
    path: 'listar-mantenimiento',
    loadChildren: () => import('./pages/mantenimiento/listar/listar.module').then( m => m.ListarPageModule)
  },
  {
    path: 'editar-mantenimiento/:idMantenimiento',
    loadChildren: () => import('./pages/mantenimiento/editar/editar.module').then( m => m.EditarPageModule)
  },
  {
    path: 'nuevo-mantenimiento',
    loadChildren: () => import('./pages/mantenimiento/nuevo/nuevo.module').then( m => m.NuevoPageModule)
  },
  {
    path: 'listar-ubicaciones',
    loadChildren: () => import('./pages/ubicaciones/listar/listar.module').then( m => m.ListarPageModule)
  },
  {
    path: 'nuevo-ubicaciones',
    loadChildren: () => import('./pages/ubicaciones/nuevo/nuevo.module').then( m => m.NuevoPageModule)
  },
  {
    path: 'editar-ubicaciones/:idUbicacion',
    loadChildren: () => import('./pages/ubicaciones/editar/editar.module').then( m => m.EditarPageModule)
  },
  {
    path: 'eliminar-ubicaciones/:idUbicacion',
    loadChildren: () => import('./pages/ubicaciones/eliminar/eliminar.module').then( m => m.EliminarPageModule)
  },
  {
    path: 'nuevo-salon-ubicaciones/:idUbicacion',
    loadChildren: () => import('./pages/ubicaciones/nuevo-salon/nuevo-salon.module').then( m => m.NuevoSalonPageModule)
  },
  {
    path: 'editar-salon-ubicaciones/:idUbicacion',
    loadChildren: () => import('./pages/ubicaciones/editar-salon/editar-salon.module').then( m => m.EditarSalonPageModule)
  },
  {
    path: 'eliminar-salon-ubicaciones/:idUbicacion',
    loadChildren: () => import('./pages/ubicaciones/eliminar-salon/eliminar-salon.module').then( m => m.EliminarSalonPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
